import React, { useState } from 'react';
import './ItineraryCollaborate.css';
import { useUser } from '../../UserContext'; // 引入 useUser

import config from '../../config'; // 引入 config.js

const Url = config.apiUrl;

function ItineraryCollaborate() {



}

export default ItineraryCollaborate;